import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/SEO'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div style={{
      textAlign: 'center',
      paddingTop: '75px',
      paddingBottom: '175px'
    }}>
      <h1>NOT FOUND</h1>
      <p>This page does not exist.</p>
    </div>
  </Layout>
)

export default NotFoundPage
